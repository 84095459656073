import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { FC } from 'react'
import infoIcon from '../../../../images/info.png'

const useStyles = makeStyles((theme: Theme) => ({
  container: (props: { isChoosen: boolean }) => ({
    backgroundColor: props.isChoosen
      ? theme.palette.primary.contrastText
      : 'white',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: 180,
    },
    width: 240,
    height: 160,
    borderRadius: theme.spacing(1.5),
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1.5,
    )}px ${theme.spacing(1)}px`,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.dark,
    },
  }),

  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  statusText: {
    textAlign: 'center',
  },
}))

interface LegalStatusCaseProps {
  status: string
  isChoosen: boolean
  handleClick: () => void
}

const LegalStatusCase: FC<LegalStatusCaseProps> = props => {
  const { status, isChoosen, handleClick } = props

  const { container, imageContainer, statusText } = useStyles({ isChoosen })

  return (
    <Paper elevation={8} className={container} onClick={handleClick}>
      <Box className={imageContainer} paddingY={2}>
        <img src={infoIcon} alt="info icon" />
      </Box>
      <Box className={statusText}>
        <Typography variant="subtitle1">{status} </Typography>
      </Box>
    </Paper>
  )
}

export default LegalStatusCase
