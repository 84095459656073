import { Box, Theme, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/styles'
import React, { useRef, useState } from 'react'
import Swiper from 'react-id-swiper'
import { useSelector } from 'react-redux'
import TextMarkdown from '../components/customMarkdown/textMarkdown'
import SwiperNavigation from '../components/libs/swiperNavigation'
import DownloadCase from '../components/pages/documentsLegalForm/downloadCase'
import LegalStatusCase from '../components/pages/documentsLegalForm/legalStatusCase'
import SEO from '../components/seo'
import { SWIPER_PARAMS_FULL } from '../constants/common'
import { LegalStatusData } from '../hooks/useAllLegalStatus'
import useDocumentsLegalForm, {
  DocumentsLegalFormLists,
} from '../hooks/useDocumentsLegalForm'
import { RootState } from '../redux/store'
import { getInternationalizedValue } from '../utils/common'
import {
  getDoclumentsByStatusId,
  getDocumentsLegalStatus,
} from '../utils/documentsLegalForm'
import { sortLegalStatusByPriority } from '../utils/legalStatus'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // marginTop: theme.spacing(12),
    width: '98%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  container: {
    // padding: theme.spacing(2),
    padding: `0px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(0.5),
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },

    background:
      'linear-gradient(180deg, #C9FFEF 0%, rgba(255, 255, 255, 0) 100%), #FFF',
  },
}))

const DocumentsLegalForm = () => {
  const swiperRef = useRef<any>(null)
  const [choosenStatus, setChoosenStatus] = useState(11)

  const data = useDocumentsLegalForm()
  const legalStatusLists = getDocumentsLegalStatus(data)
  const sortedStatus = sortLegalStatusByPriority(
    legalStatusLists as LegalStatusData[],
  )

  // console.log('!!', legalStatusLists)
  const { language } = useSelector((state: RootState) => state.app)

  const currentDocument = getDoclumentsByStatusId(data, choosenStatus)
  const intlDocument = getInternationalizedValue(
    currentDocument,
    'document_list',
    language,
  )

  const documentsList: DocumentsLegalFormLists[] =
    currentDocument && currentDocument.documents_lists
      ? currentDocument.documents_lists
      : []

  const { root, container } = useStyles()

  const handleSetChoosenStatus = (statusId: number) => () => {
    setChoosenStatus(statusId)
  }

  return (
    <Box className={root}>
      <SEO title="DocumentsLegalForm" />
      <Box paddingBottom={4} textAlign="center">
        <Typography variant="h4">
          LISTE DES DOCUMENTS TÉLÉCHARGEABLES
        </Typography>
      </Box>
      <Box className={container}>
        {/* Legal status choice section */}
        <Box>
          <Box paddingY={1}>
            <Typography variant="h5">
              CHOISISSEZ VOTRE FORME JURIDIQUE
            </Typography>
          </Box>
          <Box display="flex" paddingLeft={1}>
            <Swiper {...SWIPER_PARAMS_FULL} ref={swiperRef}>
              {sortedStatus.map(status => {
                const statusText = getInternationalizedValue(
                  status.Status,
                  'legal_status',
                  language,
                )
                const formatedStatus = `${statusText} - ${status.STATUS}`
                return (
                  <Box key={`status-choice-${status.id}`}>
                    <LegalStatusCase
                      isChoosen={+choosenStatus === +status.id}
                      status={formatedStatus}
                      handleClick={handleSetChoosenStatus(+status.id)}
                    />
                  </Box>
                )
              })}
            </Swiper>
          </Box>
          <Box marginY={2}>
            <SwiperNavigation swiperRef={swiperRef} />
          </Box>
        </Box>

        {/* Description section */}
        <Box marginY={4}>
          <Typography variant="body1">
            <TextMarkdown text={intlDocument} />
          </Typography>
        </Box>

        {/* Buttons section */}
        <Box marginY={2}>
          {documentsList.map((d, index) => (
            <Box key={`download-form-${d.id}`}>
              <DownloadCase data={d} />
              {index + 1 !== documentsList.length ? <Divider /> : null}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default DocumentsLegalForm
