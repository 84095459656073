import { graphql, useStaticQuery } from 'gatsby'

export interface DocumentsLegalFormLegalStatus {
  id: number
  STATUS: string
  priority: string
  Status: {
    id: number
    legal_status_mg: string
    legal_status_fr: string
    legal_status_en: string
  }
}

export interface DocumentsLegalFormLists {
  id: string
  document_name_fr: string
  model_link_txt_fr: string
}

export interface DocumentsLegalFormData {
  id: string
  document_list_mg: string
  document_list_fr: string
  document_list_en: string
  documents_lists: DocumentsLegalFormLists[]
  legal_status_list: DocumentsLegalFormLegalStatus
}

export default (): DocumentsLegalFormData[] => {
  const data = useStaticQuery(graphql`
    {
      allStrapiDocumentByLegalForms {
        nodes {
          id
          document_list_mg
          document_list_fr
          document_list_en
          documents_lists {
            id
            document_name_fr
            model_link_txt_fr
          }
          legal_status_list {
            id
            STATUS
            priority
            Status {
              id
              legal_status_mg
              legal_status_fr
              legal_status_en
            }
          }
        }
      }
    }
  `)

  return data.allStrapiDocumentByLegalForms.nodes
}
