import { LegalStatusChoiceData } from '../hooks/legalStatusChoice/useLegalStatusChoice'
import { LegalStatusData } from '../hooks/useAllLegalStatus'
import { getNumberId } from './common'

export const getLegalStatusById = (
  id: string,
  allLegalStatus: LegalStatusChoiceData[],
): LegalStatusChoiceData | undefined => allLegalStatus.find(l => l.id === id)

export const sortLegalStatusByPriority = (
  legalStatus: LegalStatusData[],
): LegalStatusData[] =>
  legalStatus.sort((a: LegalStatusData, b: LegalStatusData) =>
    +a.priority > +b.priority ? 1 : +a.priority < +b.priority ? -1 : 0,
  )

export const createMapLegalStatusAndId = (
  statusData: any[],
): Map<string, string> => {
  const statusMap = new Map<string, string>()
  for (const status of statusData) {
    const id = getNumberId(status.id)
    statusMap.set(status.STATUS, id.toString())
  }
  return statusMap
}
